exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admission-js": () => import("./../../../src/pages/admission.js" /* webpackChunkName: "component---src-pages-admission-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-club-js": () => import("./../../../src/pages/club.js" /* webpackChunkName: "component---src-pages-club-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-goodtogo-js": () => import("./../../../src/pages/goodtogo.js" /* webpackChunkName: "component---src-pages-goodtogo-js" */),
  "component---src-pages-holiday-js": () => import("./../../../src/pages/holiday.js" /* webpackChunkName: "component---src-pages-holiday-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partypackage-js": () => import("./../../../src/pages/partypackage.js" /* webpackChunkName: "component---src-pages-partypackage-js" */),
  "component---src-pages-playtime-js": () => import("./../../../src/pages/playtime.js" /* webpackChunkName: "component---src-pages-playtime-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-waiver-js": () => import("./../../../src/pages/waiver.js" /* webpackChunkName: "component---src-pages-waiver-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */)
}

